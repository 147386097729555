import { Layout, UserContext } from '../components/Layout'
import React from 'react'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { Grid, Cell } from 'baseui/layout-grid'
import { HorizontalLine } from '../components/Line'
import { useStyletron } from 'baseui'
import { ListNavItem, ListNav } from '../components/ListNav'
import { loginRedirect } from '../utils'

export const HazardHomeNav = () => (
  <ListNav>
    <ListNavItem url="/hazard-list" testId="hazard-list-button">
      Find out information on a hazard
    </ListNavItem>
    <ListNavItem url="/issues/issue-list" testId="issue-list-button">
      My logged issues
    </ListNavItem>
    <ListNavItem url="/issues/create-issue" testId="create-issue">
      Log an issue
    </ListNavItem>
  </ListNav>
)

export const HazardHomePage = () => {
  const { token } = React.useContext(UserContext)
  const [css] = useStyletron()

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
  }, [token])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <HeadingLevel>
            <Heading data-testid="hazard-heading">Hazards</Heading>
            <HorizontalLine
              className={css({
                marginBottom: '8px'
              })}
            />
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <HazardHomeNav />
        </Cell>
      </Grid>
    </>
  )
}

const LayoutHazardHomePage = () => {
  return (
    <Layout>
      <HazardHomePage />
    </Layout>
  )
}

export default LayoutHazardHomePage
